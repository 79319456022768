import { View, StyleSheet, Text } from "react-native";
import styled from "styled-components/native";
import { BarCodeEvent, BarCodeScanner } from "expo-barcode-scanner";
import { useEffect, useState } from "react";
import { NavigationType } from "../types";
import { useTmpStore } from "../hooks/useTmpStore";
import { ethers } from "ethers";
import { Camera } from "expo-camera";

const Container = styled(View)`
  background-color: #000;
  flex: 1;
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

type Props = {
  navigation: NavigationType;
  route: {
    params?: {
      isAddressScan?: boolean;
    };
  };
};

export function ScanScreen({ navigation, route }: Props) {
  const setToAddress = useTmpStore((store) => store.setToAddress);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      console.log("stat", status);
      setHasPermission(status === "granted");
    })();

    if (__DEV__) {
      // navigation.replace("DisplayDecodedAttestationScreen", {
      //   encodedQRString:
      //     "H4sIAP8kaGICA6VSTW/bMAz9Lz4Hg0SJopRblw+gtwFrT0MPtD5aH+IEtlc0KPrfRyVN2rVAsKECDFvm4yP5+J6bsbvv89DMG/Wk12iWEBGDWq51IIjBcWFrDTkNyCroXAx8b2Y1q5k/N4/NHPyseU0n1Vom02JO4I1Snh3lrEpKgDbnnBi8tRgtARoiSJ48Z1CBrI2MlfZApLwGV1BHzZ6QnAuhRK0xW2Sm6Kjo0hYkr5OyrUmYokVERYYMqLYSJZ64Npi2G+76+tXzJgv76uqnhB/zMHbbvlb75uQeHwR1nZq5PcS6su/6+8W2nwaO06EnVpjNghUsvMixCkFpv0KMCxNArZcUil0vHdjmZdbshm7Dw/5mv6sVr6Ypj5MU2eRx5Ptcmxly7HZd7o/c6h9PFSg+5A0f5S4RvSZWomsCA9YURT4a+YctlNZgSYVLTm3xxWunY8SSZamuDSkWI2z5adcNPIkSN91BHRGZZB4fQKSUlIAWDJIKHmWdzlOQEmhANuKtc+isqo8JFgm9VYqCNhqCMyGYuochl9vb6+V/jXlh/ONav8xF7ZfSdfRfHEXLLP22j1VzVS0ziVfG6ozV9Q/SsHy17a+zbw+vI05u4zSIQSXxHD85+gLkZPIz5HfXT4DuA80H85/RnNIgDm5e7mYnT7/r783RnxPe6F/te4a0e+Ex8B7ywZOXmj2Z6xLdwTF/A96Hj0v4XOTuRc4fL0j0HB0FAAA=",
      // });
    }
  }, []);

  if (hasPermission === null || !hasPermission) {
    return <View />;
  }

  return (
    <Container>
      <Camera
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
        onBarCodeScanned={async (codeEvent: BarCodeEvent) => {
          console.log("scan event");
          if (
            route.params?.isAddressScan &&
            ethers.utils.isAddress(codeEvent.data)
          ) {
            setToAddress(codeEvent.data);
            navigation.pop();
            return;
          }

          navigation.replace("DisplayDecodedAttestationScreen", {
            encodedQRString: codeEvent.data,
          });
        }}
        style={StyleSheet.absoluteFillObject}
      />
    </Container>
  );
}
