import React, { CSSProperties } from "react";
import styled from "styled-components/native";
import { Text, ViewStyle } from "react-native";

const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f7f8;
  border-radius: 56px;
  height: 42px;
  margin-top: 16px;
  padding: 6px;
`;

type SideProps = {
  titlesLength: number;
  selected?: boolean;
};

const Side = styled.TouchableOpacity<SideProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.selected ? "#FFFFFF" : "#f2f7f8")};
  border-radius: 56px;
  height: 30px;
  width: ${(props) => 100 / props.titlesLength}%;
  shadow-color: rgba(22, 44, 55, 0.12);
  shadow-offset: 0px 2px;
  shadow-opacity: ${(props) => (props.selected ? 1 : 0)};
  shadow-radius: 3px;
`;

type TitleProps = {
  selected: boolean;
  dontTransformText?: boolean;
};

const Title = styled(Text)<TitleProps>`
  text-transform: ${(props) =>
    props.dontTransformText ? "none" : "capitalize"};
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${({ theme, selected }) => (selected ? theme.dark : theme.gray)};
`;

interface Props {
  titles: string[];
  onSelect: (i: number) => void;
  selected: number;
  dontTransformText?: boolean;
  style?: ViewStyle;
}

export function SexySwitch({
  titles = ["Left", "Right"],
  onSelect,
  selected = 0,
  dontTransformText,
  style,
}: Props) {
  return (
    <Container style={style}>
      {titles.map((title, i) => (
        <Side
          key={i}
          selected={i === selected}
          onPress={() => onSelect(i)}
          titlesLength={titles.length}
        >
          <Title
            selected={i === selected}
            dontTransformText={dontTransformText}
          >
            {titles[i]}
          </Title>
        </Side>
      ))}
    </Container>
  );
}
