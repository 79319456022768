import { StatusBar, Text, View } from "react-native";
import styled from "styled-components/native";
import TopSpacer from "../components/TopSpacer";
import { BigButton } from "../components/imported/rn-pretty/big-button";
import { createNewWallet } from "../utils";
import { useEffect, useState } from "react";
import { useStore } from "../hooks/useStore";
import { NavigationType } from "../types";
import { Wallet } from "ethers";

const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.primary};
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const Button = styled(BigButton)`
  margin-top: 12px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 40px;
`;

const MnemonicContainer = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.darkest};
  padding: 12px;
  border-radius: 4px;
`;

const Mnemonic = styled(Text)`
  color: #fff;
  font-size: 22px;
`;

const Word = styled(View)`
  padding: 2px 4px;
`;

type Props = {
  navigation: NavigationType;
};

export function CreateIdentityScreen({ navigation }: Props) {
  const [wallet, setWallet] = useState<null | Wallet>(null);
  const initializeNewWallet = useStore((store) => store.initializeNewWallet);

  useEffect(() => {
    setWallet(createNewWallet());
  }, []);

  const mnemonic = wallet && wallet.mnemonic.phrase;

  return (
    <Container>
      <StatusBar barStyle={"light-content"} />
      <TopSpacer />
      <Title>
        {!mnemonic ? "Generating keys..." : "Write down your recovery phrase"}
      </Title>

      {mnemonic && wallet && (
        <>
          <MnemonicContainer>
            {mnemonic.split(" ").map((word, i) => (
              <Word key={i}>
                <Mnemonic>{word}</Mnemonic>
              </Word>
            ))}
          </MnemonicContainer>

          <Button
            text={"I have written it down"}
            inverted={true}
            onPress={() => {
              initializeNewWallet(mnemonic, wallet.address, wallet.privateKey);
            }}
          />
        </>
      )}
    </Container>
  );
}
