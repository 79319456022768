import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  AttestationShareablePackageObject,
  CollectionItem,
  Contact,
} from "../types";
import produce from "immer";
import dayjs from "dayjs";
import { zipAndEncodeToBase64 } from "../utils/encodingUtils";

type InitialState = {
  _hasHydrated: boolean;
  appVersion: number;
  seedPhrase: string | null;
  walletInfo: {
    address: string;
    privateKey: string;
  } | null;
  collection: CollectionItem[];
  contacts: Contact[];
};

const initialState: InitialState = {
  _hasHydrated: false,
  appVersion: 0,
  seedPhrase: null,
  walletInfo: null,
  collection: [],
  contacts: [],
};

const reducer = (set: any) => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState, _hasHydrated: true })),
  setHasHydrated: (hydrated: boolean) =>
    set({ _hasHydrated: hydrated }, undefined, "setHasHydrated"),
  setAppVersion: (version: number) =>
    set({ appVersion: version }, undefined, "setAppVersion"),
  addContact: (contact: Contact) =>
    set(
      produce((state: InitialState) => {
        state.contacts.push(contact);
      })
    ),
  removeContact: (address: string) =>
    set(
      produce((state: InitialState) => {
        state.contacts = state.contacts.filter(
          (contact) => contact.address.toLowerCase() !== address.toLowerCase()
        );
      })
    ),
  addCollectionItem: (
    attestationShareablePackageObject: AttestationShareablePackageObject,
    readableType?: string
  ) =>
    set(
      produce((state: InitialState) => {
        const attestationData =
          attestationShareablePackageObject.sig.data.message;

        state.collection.push({
          encodedAttestationPackageObject: zipAndEncodeToBase64(
            attestationShareablePackageObject
          ),
          type: "attestation-full",
          tmpUUID: Date.now().toString(),
          typeUuid: attestationData.schema,
          readableType: readableType,
          timestamp: dayjs().unix(),
          issuerAddress: attestationShareablePackageObject.signer,
          recipientAddress: attestationData.recipient,
        });
      })
    ),
  deleteCollectionItem: (uuid: string) =>
    set(
      produce((state: InitialState) => {
        state.collection = state.collection.filter(
          (item) => item.uuid !== uuid && item.tmpUUID !== uuid
        );
      })
    ),
  initializeNewWallet: (phrase: string, address: string, privateKey: string) =>
    set(
      { seedPhrase: phrase, walletInfo: { address, privateKey } },
      undefined,
      "initializeNewWallet"
    ),
});

export const useStore = create(
  persist(__DEV__ ? devtools(reducer) : reducer, {
    name: "nibble-app", // unique name
    getStorage: () => AsyncStorage, // (optional) by default, 'localStorage' is used,
    onRehydrateStorage: () => (state: any) => state?.setHasHydrated(true),
  })
);
