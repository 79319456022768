import beautify from "json-beautify";
import styled from "styled-components/native";
import { Text, View } from "react-native";

const Bold = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  font-size: 14px;
`;

const SchemaStringContainer = styled(View)`
  background-color: ${({ theme }) => theme.lightGray};
  padding: 10px;
  border-radius: 8px;
  margin-top: 12px;
`;

const SchemaString = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 16px;
`;

type Props = { value: object; title?: string };

export function PrettyJsonBlock({ value, title }: Props) {
  return (
    <SchemaStringContainer>
      {title && <Bold>{title}</Bold>}
      <SchemaString selectable={true}>
        {beautify(value, null as any, 5, 40)}
      </SchemaString>
    </SchemaStringContainer>
  );
}
