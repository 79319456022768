import { Platform, SectionList, StatusBar, Text, View } from "react-native";
import styled from "styled-components/native";
import { useStore } from "../hooks/useStore";
import { CollectionItemBlock } from "../components/CollectionItemBlock";
import produce from "immer";
import { CollectionItem } from "../types";
import { SafeAreaView } from "react-native-safe-area-context";

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: #fff;
  padding-bottom: -10px;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const HeaderContainer = styled(View)`
  background-color: #eee;
  padding: 6px 12px;
`;

const HeaderText = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  font-size: 22px;
  color: ${({ theme }) => theme.darkest};
  text-transform: capitalize;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.darkest};
  text-align: left;
  font-size: 22px;
  line-height: 28px;
`;

const Empty = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.darkest};
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
`;

const ListContainer = styled(View)`
  margin-top: 8px;
`;

export function CollectionScreen() {
  const collection = useStore((store) => store.collection);
  const walletInfo = useStore((store) => store.walletInfo);

  if (!walletInfo) {
    return <View />;
  }

  const sortedCollection = produce(collection, (draftCollection) => {
    draftCollection.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  });

  const myItems = sortedCollection.filter(
    (item) =>
      item.recipientAddress.toLowerCase() === walletInfo.address.toLowerCase()
  );

  const itemsFromMe = sortedCollection.filter(
    (item) =>
      item.issuerAddress.toLowerCase() === walletInfo.address.toLowerCase() &&
      !myItems.find(
        (myItem) =>
          (item.uuid && item.uuid === myItem.uuid) ||
          (item.tmpUUID && item.tmpUUID === myItem.tmpUUID)
      )
  );

  const itemsNotFromMe = sortedCollection.filter(
    (item) =>
      item.issuerAddress.toLowerCase() !== walletInfo.address.toLowerCase() &&
      !myItems.find(
        (myItem) =>
          (item.uuid && item.uuid === myItem.uuid) ||
          (item.tmpUUID && item.tmpUUID === myItem.tmpUUID)
      )
  );

  const tmpSections = [
    {
      title: "received",
      data: myItems,
    },
    {
      title: "sent",
      data: itemsFromMe,
    },
    {
      title: "imported",
      data: itemsNotFromMe,
    },
  ];

  const sections = tmpSections.filter((section) => section.data.length > 0);

  return (
    <Container>
      <Padding>
        <Title>Collection</Title>
      </Padding>
      <ListContainer>
        {sections.length ? (
          <SectionList<CollectionItem>
            style={{ height: "100%" }}
            renderSectionHeader={({ section: { title } }) => (
              <HeaderContainer>
                <HeaderText>{title}</HeaderText>
              </HeaderContainer>
            )}
            sections={sections}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, section }) => (
              <CollectionItemBlock item={item} sectionName={section.title} />
            )}
          />
        ) : (
          <Empty>Nothing to see here yet</Empty>
        )}
      </ListContainer>
    </Container>
  );
}
