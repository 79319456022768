import { Identicon } from "../components/Identicon";
import styled from "styled-components/native";
import { Text, TouchableOpacity } from "react-native";
import { AddressDisplay } from "./AddressDisplay";
import { useNavigation } from "@react-navigation/native";
import { NavigationType } from "../types";

const IdenticonHolder = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
`;

const AccountName = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  text-align: center;
  font-size: 20px;
`;

const Address = styled(AddressDisplay)`
  margin-top: 2px;
`;
type Props = {
  address: string;
  hideAddress?: boolean;
};

export function AccountHeader({ address, hideAddress }: Props) {
  const navigation = useNavigation<NavigationType>();

  return (
    <>
      <IdenticonHolder
        onPress={() => navigation.navigate("AccountDetailsScreen")}
      >
        <Identicon address={address} size={120} />
      </IdenticonHolder>
      <AccountName>Identity One</AccountName>
      {!hideAddress && <Address address={address} />}
    </>
  );
}
