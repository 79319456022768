import React from "react";
import styled from "styled-components/native";
import { TouchableOpacity, Text } from "react-native";

interface ContainerProps {
  disabled?: boolean;
  inverted?: boolean;
  dark?: boolean;
}

interface TitleProps {
  inverted?: boolean;
  dark?: boolean;
  large?: boolean;
  titleColor?: string;
}

const Container = styled(TouchableOpacity)<ContainerProps>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  background-color: ${({ disabled, theme, inverted }) =>
    disabled ? "#ccc" : inverted ? "#FFF" : theme.primary};
  border-radius: 10px;
  border-width: ${({ dark }) => (dark ? 1 : 0)}px;
  border-color: #b3bfc9;
`;

const Title = styled(Text)<TitleProps>`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-size: ${({ large }) => (large ? 18 : 16)}px;

  color: ${({ dark, inverted, theme, titleColor }) =>
    titleColor
      ? titleColor
      : dark
      ? theme.darkest
      : inverted
      ? theme.primary
      : "#FFF"};
`;

interface Props {
  onPress: () => void;
  text: string;
  inverted?: boolean;
  disabled?: boolean;
  large?: boolean;
  dark?: boolean;
  titleColor?: string;
  style?: object;
  testID?: string;
}

export function BigButton({
  onPress,
  text = "Get started",
  inverted,
  large,
  dark,
  testID,
  disabled,
  style,
  titleColor,
}: Props) {
  return (
    <Container
      dark={dark}
      inverted={inverted}
      disabled={disabled}
      testID={testID}
      style={style}
      onPress={onPress}
    >
      <Title
        large={large}
        dark={dark}
        inverted={inverted}
        titleColor={titleColor}
      >
        {text}
      </Title>
    </Container>
  );
}
