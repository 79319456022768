import {
  Alert,
  Platform,
  SafeAreaView as SafeAreaViewIos,
  ScrollView,
  Share,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import styled, { useTheme } from "styled-components/native";
import { BigButton } from "../components/imported/rn-pretty/big-button";
import { SafeAreaView as SafeAreaViewAndroid } from "react-native-safe-area-context";
import { AttestationShareablePackageObject, NavigationType } from "../types";
import { useEffect, useState } from "react";
import {
  formatAddress,
  getAttestationTypeBySchemaAddress,
  verifyProxyAttestation,
} from "../utils";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useStore } from "../hooks/useStore";
import * as Linking from "expo-linking";
import { PrettyJsonBlock } from "../components/PrettyJsonBlock";
import { DecodedAttestationValue } from "./DecodedAttestationValue";
import { AddressOrENSText } from "../components/AddressOrENSText";
import { useSchemaByUuid } from "../hooks/useSchemaByUuid";
import { decodeBase64ZippedQR } from "../utils/encodingUtils";

const Container = styled(ScrollView)`
  flex: 1;
  background-color: #fff;
  padding: 4px 12px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 24px;
  color: ${({ theme }) => theme.darkest};
  margin-top: 12px;
  margin-bottom: 40px;
`;

const Property = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 16px;
  color: ${({ theme }) => theme.darkest};
`;

const Value = styled(Property)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
`;

const Green = styled(Value)`
  color: ${({ theme }) => theme.green};
`;
const Red = styled(Value)`
  color: ${({ theme }) => theme.red};
`;

type CheckmarkTypes = {
  valid: boolean;
};

const BigCheckMark = styled(Ionicons)<CheckmarkTypes>`
  margin-top: 12px;
  text-align: center;
  color: ${({ theme, valid }) => (valid ? theme.green : theme.red)};
`;

const TopBar = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DecodedData = styled(View)`
  margin-top: 8px;
`;

const IconButtonHolder = styled(TouchableOpacity)``;

const ShareTools = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const SaveText = styled(Text)`
  color: #62a7ce;
  font-size: 18px;
  margin-right: 4px;
`;

interface Params {
  encodedQRString: string;
  storageUuid?: string;
}

type Props = {
  navigation: NavigationType;
  route: {
    params: Params;
  };
};

export function DisplayDecodedAttestationScreen({ navigation, route }: Props) {
  const [data, setData] = useState<null | AttestationShareablePackageObject>(
    null
  );
  const [verified, setVerified] = useState<null | boolean>(null);
  const [advanced, setAdvanced] = useState<boolean>(false);
  const [badData, setBadData] = useState<null | boolean>(null);
  const addCollectionItem = useStore((store) => store.addCollectionItem);
  const deleteCollectionItem = useStore((store) => store.deleteCollectionItem);
  const attestationData = data?.sig.data.message;
  const attestationType =
    attestationData &&
    getAttestationTypeBySchemaAddress(attestationData?.schema);
  const theme = useTheme();

  const { data: schemaData } = useSchemaByUuid(attestationData);

  async function validate() {
    try {
      const obj = decodeBase64ZippedQR(route.params.encodedQRString);
      const tmpVerified = await verifyProxyAttestation(obj.sig, obj.signer);

      if (
        !obj.sig.data ||
        !obj.sig.data.message.data ||
        !obj.sig.data.message.schema
      ) {
        setBadData(true);
        return;
      }

      setData(obj);
      setVerified(tmpVerified);
    } catch (e) {
      console.log("err", e);
    }
  }

  useEffect(() => {
    validate();
  }, []);

  const SafeComponent =
    Platform.OS === "ios" ? SafeAreaViewIos : SafeAreaViewAndroid;

  return (
    <SafeComponent style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Container>
        <TopBar>
          <Title>{attestationType?.shortName ?? "Unlisted"} Attestation</Title>
          {!route.params.storageUuid ? (
            <IconButtonHolder
              onPress={() => {
                if (!data || !attestationData) {
                  return;
                }

                if (!route.params.storageUuid) {
                  addCollectionItem(
                    data,
                    attestationType?.shortName ?? "Custom"
                  );
                }

                navigation.pop();
                navigation.navigate("CollectionScreen");
              }}
            >
              <SaveText>{"Save"}</SaveText>
            </IconButtonHolder>
          ) : (
            <ShareTools>
              <IconButtonHolder
                onPress={() => {
                  navigation.navigate("QRDisplayScreen", {
                    qrString: route.params.encodedQRString,
                    subtitle: "Shareable QR code",
                  });
                }}
                style={{
                  marginRight: 10,
                }}
              >
                <Ionicons
                  name={"qr-code-outline"}
                  size={24}
                  style={{ top: 8 }}
                />
              </IconButtonHolder>

              <IconButtonHolder
                onPress={async () => {
                  const redirectUrl = Linking.createURL("showAttestation", {
                    queryParams: {
                      base64AttestationData: route.params.encodedQRString,
                    },
                  });

                  console.log("re", redirectUrl);
                  await Share.share({ url: redirectUrl });
                }}
              >
                <Ionicons name={"share-outline"} size={24} style={{ top: 8 }} />
              </IconButtonHolder>
              {Platform.OS === "web" && (
                <IconButtonHolder
                  onPress={() => {
                    navigation.pop();
                  }}
                >
                  <SaveText>{"Close"}</SaveText>
                </IconButtonHolder>
              )}
            </ShareTools>
          )}
        </TopBar>
        {!badData && attestationData && data ? (
          <>
            <Property>
              Valid signature:{" "}
              {verified ? <Green>PASSED</Green> : <Red>FAILED</Red>}
            </Property>
            <Property>
              Schema: <Value>{formatAddress(attestationData.schema)}</Value>
            </Property>
            <Property numberOfLines={1}>
              Signer:{" "}
              <Value>
                <AddressOrENSText address={data.signer} />
              </Value>
            </Property>
            <Property numberOfLines={1}>
              Recipient:{" "}
              <Value>
                <AddressOrENSText
                  address={attestationData.recipient}
                  showBoth={true}
                />
              </Value>
            </Property>

            {schemaData && (
              <DecodedData>
                {schemaData.map((value, i) => (
                  <DecodedAttestationValue key={i} value={value} />
                ))}
              </DecodedData>
            )}

            <BigButton
              onPress={() => setAdvanced(!advanced)}
              text={advanced ? "Hide details" : "Show advanced details"}
              inverted={true}
            />

            {data && advanced && (
              <PrettyJsonBlock value={data} title={"Raw attestation data"} />
            )}

            <BigCheckMark
              valid={verified}
              name={
                verified
                  ? "md-checkmark-circle-outline"
                  : "md-close-circle-outline"
              }
              size={180}
            />

            {route.params.storageUuid && (
              <BigButton
                onPress={() => {
                  if (!route.params.storageUuid) {
                    return;
                  }

                  Alert.alert(
                    "Delete attestation",
                    "Are you sure you want to remove this attestation from storage?",
                    [
                      {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel",
                      },
                      {
                        text: "OK",
                        onPress: () => {
                          deleteCollectionItem(
                            route.params.storageUuid as string
                          );
                          navigation.pop();
                        },
                      },
                    ]
                  );
                }}
                titleColor={theme.red}
                text={"Delete item"}
                inverted={true}
              />
            )}
          </>
        ) : (
          <>
            <Property>Bad Data</Property>
          </>
        )}
      </Container>
    </SafeComponent>
  );
}
