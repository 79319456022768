import React from "react";
import {
  GestureResponderEvent,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import styled from "styled-components/native";

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const RightButtonHolder = styled(TouchableOpacity)``;

const RightButtonText = styled(Text)`
  color: #62a7ce;
  font-size: 18px;
  margin-top: 14px;
  margin-right: 4px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.darkest};
  font-size: 20px;
`;

const Subtitle = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.gray};
  font-size: 14px;
  margin-bottom: 8px;
`;

type Props = {
  name: string;
  subtitle?: string;
  rightButtonText?: string;
  rightButtonOnPress?: (event: GestureResponderEvent) => void;
};

export function TopBar({
  name,
  subtitle,
  rightButtonOnPress,
  rightButtonText,
}: Props) {
  return (
    <Container>
      <View>
        <Title>{name}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </View>
      <RightButtonHolder onPress={rightButtonOnPress}>
        <RightButtonText>{rightButtonText}</RightButtonText>
      </RightButtonHolder>
    </Container>
  );
}
