import {
  Dimensions,
  Platform,
  SafeAreaView as SafeAreaViewIos,
  ScrollView,
  Text,
  View,
} from "react-native";
import styled from "styled-components/native";
import { useStore } from "../../hooks/useStore";
import { BigInput } from "../../components/imported/rn-pretty/big-input";
import { BigButton } from "../../components/imported/rn-pretty/big-button";
import {
  attestationTypes,
  decodeSchema,
  encodeSchemaData,
  getSchemaFromGraphByIndex,
  makeProxyAttestation,
} from "../../utils";
import { useEffect, useState } from "react";
import QRCode from "react-native-qrcode-svg";
import TopSpacer from "../../components/TopSpacer";
import {
  AttestationShareablePackageObject,
  DecodedSchemaItem,
  GraphSchema,
  NavigationType,
} from "../../types";
import { useTmpStore } from "../../hooks/useTmpStore";
import { TopBar } from "../../components/TopBar";
import { useQuery } from "react-query";
import { AutoInput } from "../../components/AutoInput";
import produce from "immer";
import { ethers } from "ethers";
import { AddressInputWithScan } from "../../components/AddressInputWithScan";
import { SafeAreaView as SafeAreaViewAndroid } from "react-native-safe-area-context";
import { SchemaSearchList } from "./SchemaSearchList";
import { zipAndEncodeToBase64 } from "../../utils/encodingUtils";

const Container = styled(View)`
  flex: 1;
  background-color: #fff;
`;

const QRContainer = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: 20px;
`;

const InputValues = styled(ScrollView)``;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.red};
  margin-top: 12px;
  text-align: center;
`;
const Recipient = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  margin-top: 12px;
`;

const Bold = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  font-size: 14px;
`;

const SchemaStringContainer = styled(View)`
  background-color: ${({ theme }) => theme.lightGray};
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const SchemaString = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 16px;
`;

const SavedText = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
`;

const Property = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 16px;
  color: ${({ theme }) => theme.darkest};
`;

const Value = styled(Property)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
`;

const SchemaInfo = styled(View)`
  background-color: #eee;
  padding: 8px;
  border-radius: 4px;
`;

type Props = {
  navigation: NavigationType;
  route: {
    params: {
      schemaIndex: number;
    };
  };
};

export function CustomAttestationScreen({ navigation, route }: Props) {
  const walletInfo = useStore((store) => store.walletInfo);
  const toAddress = useTmpStore((store) => store.toAddress);
  const setToAddress = useTmpStore((store) => store.setToAddress);
  const [attestationData, setAttestationData] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [schemaIndex, setSchemaIndex] = useState<string>(
    route?.params?.schemaIndex?.toString() ?? ""
  );
  const [isSchemaLocked, setIsSchemaLocked] = useState<boolean>(
    !!route?.params?.schemaIndex
  );
  const addCollectionItem = useStore((store) => store.addCollectionItem);
  const [inputValues, setInputValues] = useState<DecodedSchemaItem[] | null>(
    null
  );

  // Clear global address on dismount
  useEffect(() => {
    return () => setToAddress("");
  }, []);

  const { data: schemaData } = useQuery(
    ["AS", schemaIndex],
    () => getSchemaFromGraphByIndex(schemaIndex),
    {
      enabled: !isNaN(schemaIndex as any) && schemaIndex !== "",
      onSuccess: (data) => {
        const schema: GraphSchema | undefined = data?.data?.data?.asschemas[0];

        if (schema) {
          const items = decodeSchema(schema.schema);

          setInputValues(items);
        }
      },
    }
  );

  const schema: GraphSchema | undefined = schemaData?.data?.data?.asschemas[0];

  const windowSize = Dimensions.get("window");

  const attestationType = attestationTypes.find(
    (type) => type.schemaAddress === schema?.id
  );

  if (!walletInfo) {
    return <View />;
  }

  const makeAttestation = async () => {
    if (!inputValues || !schema) {
      return;
    }

    setError(null);

    const encoded = encodeSchemaData(inputValues);

    const recipient = ethers.utils.isAddress(toAddress)
      ? toAddress
      : ethers.constants.AddressZero;

    const sig = await makeProxyAttestation({
      recipient: recipient,
      schema: schema.id,
      expirationTime: ethers.constants.MaxUint256.toString(),
      refUUID: ethers.constants.HashZero,
      data: encoded as any,
      nonce: ethers.BigNumber.from(0).toString(),
    });

    const qrPackage: AttestationShareablePackageObject = {
      signer: walletInfo.address,
      sig,
    };

    const base64 = zipAndEncodeToBase64(qrPackage);

    setAttestationData(base64);
    addCollectionItem(qrPackage, attestationType?.shortName ?? "Custom");
    setToAddress("");
  };

  const SafeComponent =
    Platform.OS === "ios" ? SafeAreaViewIos : SafeAreaViewAndroid;

  return (
    <SafeComponent style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Container>
        <TopSpacer minimal={true} />
        <Padding>
          <TopBar
            name={attestationType?.name ?? "Custom"}
            subtitle={
              attestationType?.subtitle ?? "Use a schema from the EAS registry"
            }
            rightButtonOnPress={() => navigation.pop()}
            rightButtonText={"Close"}
          />

          {!attestationData && !isSchemaLocked && (
            <>
              <BigInput
                title={"Schema Index"}
                keyboardType={"number-pad"}
                onChangeText={setSchemaIndex}
                value={schemaIndex}
              />

              {schema && (
                <>
                  <SchemaStringContainer>
                    <Bold>Schema definition</Bold>
                    <SchemaString>{schema.schema}</SchemaString>
                  </SchemaStringContainer>

                  <BigButton
                    onPress={() => setIsSchemaLocked(true)}
                    text={"Use schema"}
                  />
                </>
              )}
              <SchemaSearchList
                onSelect={(index) => {
                  setSchemaIndex(index.toString());
                  setIsSchemaLocked(true);
                }}
              />
            </>
          )}

          {schema && isSchemaLocked && (
            <SchemaInfo>
              <Property>Schema #</Property>
              <Value>{schema.index}</Value>
              <Property>UUID</Property>
              <Value>{schema.id}</Value>
            </SchemaInfo>
          )}

          {schema && isSchemaLocked && inputValues && !attestationData && (
            <InputValues contentInset={{ bottom: 80 }}>
              <Recipient>Recipient</Recipient>
              <AddressInputWithScan
                title={ethers.constants.AddressZero}
                value={toAddress}
                onChangeText={setToAddress}
                forceTitleOff={true}
              />

              {inputValues.map((input, i) => (
                <AutoInput
                  key={i}
                  input={input}
                  onChange={(value) => {
                    const tmpInputValues = produce(inputValues, (draft) => {
                      draft[i].value = value;
                    });

                    setInputValues(tmpInputValues);
                  }}
                />
              ))}

              <BigButton onPress={makeAttestation} text={"Attest"} />
            </InputValues>
          )}

          {error && <ErrorText>{error}</ErrorText>}
        </Padding>

        {attestationData && (
          <QRContainer>
            <QRCode
              value={attestationData}
              size={Math.min(windowSize.width - 24, 600)}
            />
            <SavedText>Saved to collections</SavedText>
          </QRContainer>
        )}
      </Container>
    </SafeComponent>
  );
}
