import styled from "styled-components/native";
import { Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { BigNumber, BytesLike, ethers } from "ethers";

const Container = styled(TouchableOpacity)``;

const BigValue = styled(Text)`
  font-size: 22px;
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
`;

const SmallValue = styled(Text)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.primaryFontFamily};
`;

type Props = {
  children: string | BigNumber;
};

export function DecodedValue({ children }: Props) {
  const [flipped, setFlipped] = useState(false);

  const isBytes = ethers.utils.isBytesLike(children);

  return (
    <Container
      onPress={() => {
        if (isBytes) {
          setFlipped(!flipped);
        }
      }}
    >
      {!flipped ? (
        <BigValue>
          {children} {isBytes && <SmallValue>[Decode hex]</SmallValue>}
        </BigValue>
      ) : (
        <BigValue>{ethers.utils.toUtf8String(children as BytesLike)}</BigValue>
      )}
    </Container>
  );
}
