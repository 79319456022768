import { DecodedSchemaItem } from "../types";
import { Text, View } from "react-native";
import { camelCaseToTitleCase } from "../utils";
import { DecodedValue } from "./DecodedValue";
import { ethers } from "ethers";
import styled from "styled-components/native";

const Property = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 16px;
  color: ${({ theme }) => theme.darkest};
`;

type Props = {
  value: DecodedSchemaItem;
};

export function DecodedAttestationValue({ value: { name, value } }: Props) {
  return (
    <View>
      <Property>{camelCaseToTitleCase(name)}</Property>
      <DecodedValue>
        {typeof value === "boolean"
          ? value
            ? "True"
            : "False"
          : ethers.utils.isAddress(value.toString())
          ? value
          : ethers.utils.isBytesLike(value)
          ? ethers.utils.toUtf8String(value)
          : ethers.BigNumber.isBigNumber(value)
          ? value.toString()
          : value}
      </DecodedValue>
    </View>
  );
}
