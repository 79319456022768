import { Dimensions, Text, View } from "react-native";
import styled from "styled-components/native";
import TopSpacer from "../components/TopSpacer";
import QRCode from "react-native-qrcode-svg";
import { TopBar } from "../components/TopBar";
import { NavigationType } from "../types";
import { formatAddress, getQueryProvider, getWalletProvider } from "../utils";
import { useStore } from "../hooks/useStore";
import { AccountHeader } from "../components/AccountHeader";
import { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useQuery } from "react-query";

const Container = styled(View)`
  flex: 1;
  background-color: #fff;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const QR = styled(View)`
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
`;

const Balance = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  font-size: 22px;
  text-align: center;
`;

type Props = {
  navigation: NavigationType;
};
const provider = getQueryProvider();

export function AccountDetailsScreen({ navigation }: Props) {
  const windowSize = Dimensions.get("window");
  const walletInfo = useStore((store) => store.walletInfo);
  const { data: balance, isLoading } = useQuery(
    ["balance", walletInfo?.address],
    () => walletInfo && provider.getBalance(walletInfo.address),
    { enabled: !!walletInfo }
  );

  if (!walletInfo) {
    return <View />;
  }

  return (
    <Container>
      <TopSpacer minimal={true} />
      <Padding>
        <AccountHeader address={walletInfo.address} />
        {balance && <Balance>{ethers.utils.formatEther(balance)} ETH</Balance>}
        {isLoading && <Balance>Loading balance...</Balance>}
        <QR>
          <QRCode
            value={walletInfo.address}
            size={Math.min(windowSize.width - 24, 600)}
          />
        </QR>
      </Padding>
    </Container>
  );
}
