import "@ethersproject/shims";
import "react-native-get-random-values";
import { ethers, Wallet } from "ethers";
import {
  Delegation,
  EIP712AttestationMessageTypes,
  EIP712AttestationParams,
  EIP712AttestationTypedData,
  EIP712AttestationTypedDataRequest,
  EIP712TypedData,
  Signature,
} from "@ethereum-attestation-service/sdk";
import {
  recoverTypedSignature,
  signTypedData,
  SignTypedDataVersion,
} from "@metamask/eth-sig-util";
import { useStore } from "./hooks/useStore";
import { hexlify } from "ethers/lib/utils";
import { AttestationType, DecodedSchemaItem } from "./types";
import axios from "axios";

// TODO: CHANGE!
export const ticketUUID =
  "0x1fc5817a0edad23243f078c38175b2fb35fdfafedbf8f8161cc5fe72c6b9dcf3";
export const trustUUID =
  "0xf0724b2b9afc912d04cf32fc959d280f1e7432744da6afacb07fc252b0e12a72";
export const usernameUUID =
  "0x1a1aac09dcf87a6662ca6f7cfda6cf8ab0d7e2b6fc4afcde3112480a36c563b1";
export const attestToStatementUUID =
  "0xa082b0a64557fd912265053a2bf90213dc3813f26ba3d116122b3ee30d5f6f9d";
export const EASContractAddress = "0xBf49E19254DF70328C6696135958C94CD6cd0430"; // Rinkeby
export const ASRegistryContractAddress =
  "0xd8B7EC70d53b11e130fba78fBED97862eF2a13f0"; // Rinkeby
export const EAS712Address = "0xa05e3Ca02C8437E99018E55cC3920FD79f4FD624"; // Rinkeby
export const EASVersion = "0.6"; // Rinkeby
export const CHAINID = 4;

export const GRAPH_API_BASE_URL =
  "https://api.studio.thegraph.com/query/4717/eas/v0.6.14";

const delegation = new Delegation({
  address: EAS712Address,
  version: EASVersion,
  chainId: CHAINID,
});

export function createNewWallet() {
  return ethers.Wallet.createRandom();
}

export async function verifyProxyAttestation(
  req: EIP712AttestationTypedDataRequest,
  signingAddress: string
): Promise<boolean> {
  const { joinSignature, hexlify } = ethers.utils;

  return await delegation.verifyAttestationTypedDataRequest(
    signingAddress,
    req,
    async (
      data: EIP712TypedData<EIP712AttestationMessageTypes>,
      signature: Signature
    ) => {
      return recoverTypedSignature({
        data,
        signature: joinSignature({
          v: signature.v,
          r: hexlify(signature.r),
          s: hexlify(signature.s),
        }),
        version: SignTypedDataVersion.V4,
      });
    }
  );
}

function getPrivateKey() {
  const { walletInfo } = useStore.getState();

  return (walletInfo as any).privateKey;
}

export function getSignerWallet(): Wallet {
  const privateKey = getPrivateKey();
  return new Wallet(privateKey);
}

export async function makeProxyAttestation(
  params: EIP712AttestationParams
): Promise<EIP712AttestationTypedDataRequest> {
  const sign = async (message: EIP712AttestationTypedData) => {
    const privateKey = getPrivateKey();
    return signTypedData({
      privateKey: Buffer.from(privateKey.slice(2), "hex"),
      data: message,
      version: SignTypedDataVersion.V4,
    });
  };

  return await delegation.getAttestationTypedDataRequest(
    params,
    async (data: EIP712AttestationTypedData) => {
      const { v, r, s } = ethers.utils.splitSignature(await sign(data));
      return {
        v,
        r: hexlify(r) as any, // TODO: had to override types
        s: hexlify(s) as any,
      };
    }
  );
}

export function formatAddress(address: string, sideLength = 8) {
  return (
    address.substring(0, sideLength) + "..." + address.slice(2 - sideLength)
  );
}

export const attestationTypes: AttestationType[] = [
  {
    name: "Proof of Humanity",
    asIndex: 6,
    subtitle: "Attest that an address is human",
    faIconName: "user-o",
    shortName: "Humanity",
    schemaAddress:
      "0xda82bd5ca4ff80acf60f31e60b8c34c770475d549190f37325790a664c6b47aa",
  },
  {
    name: "Cast a Vote",
    asIndex: 12,
    subtitle: "Attest to voting on a proposal",
    faIconName: "square-o",
    shortName: "Vote",
    schemaAddress:
      "0x98bfb2f9d82e74f0921275b1db8896d8116f6ddfbf8c30443e6f27f9fb5cd0d5",
  },
  {
    name: "Make a statement",
    asIndex: 3,
    subtitle: "Attest to any arbitrary statement",
    faIconName: "comment-o",
    shortName: "Statement",
    schemaAddress: attestToStatementUUID,
  },
  {
    name: "Trust Score",
    asIndex: 32,
    subtitle: "Attest to  someone’s trustworthiness",
    faIconName: "handshake-o",
    shortName: "Trust",
    schemaAddress:
      "0x1a77a18a0969f5b2454fa290fc34aa84e199b7aa056b685fdc1326ee2cc0ac39",
  },
  {
    name: "Credit Score",
    asIndex: 8,
    subtitle: "Attest to someone’s credit worthiness",
    faIconName: "square-o",
    shortName: "Credit Score",
    schemaAddress:
      "0xcca690adb6b98e668e00b134e2cc2e258a8227c4e6f71588e3b5200043b4404c",
  },
  {
    name: "Price Oracle",
    asIndex: 31,
    subtitle: "Attest to the price of something",
    faIconName: "square-o",
    shortName: "Price Oracle",
    schemaAddress:
      "0x836ae520b76a152630f25a389f18a0d3cf615e0f04cb6390bdffae00e6c30cfc",
  },
  {
    name: "Event Oracle",
    asIndex: 13,
    subtitle: "Attest to the outcome of an event",
    faIconName: "square-o",
    shortName: "Event Oracle",
    schemaAddress:
      "0x9bfc0dcc758d814726c12c8b231234d9fc29643880f43e28784fec6d43275009",
  },
  {
    name: "Like something",
    asIndex: 1,
    subtitle: "Attest that you like or dislike something",
    faIconName: "heart-o",
    shortName: "Like",
    schemaAddress: trustUUID,
  },
  {
    name: "Set name",
    asIndex: 2,
    subtitle: "Attest to a name for my account",
    faIconName: "user-o",
    shortName: "Name Claim",
    schemaAddress: usernameUUID,
  },
  {
    name: "Issue event ticket",
    asIndex: 11,
    subtitle: "Issue a ticket for an event",
    faIconName: "ticket",
    shortName: "Ticket",
    schemaAddress: ticketUUID,
  },
  {
    name: "Custom",
    subtitle: "Use any schema from the EAS registry",
    faIconName: "pencil",
    shortName: "Custom",
    schemaAddress: "fsdfsdf",
  },
];

export function camelCaseToTitleCase(camelString: string): string {
  const result = camelString.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getAttestationTypeBySchemaAddress(schemaAddress: string) {
  return attestationTypes.find((type) => type.schemaAddress === schemaAddress);
}

export const trustTypes = [
  {
    title: "Personal",
  },
  {
    title: "Business",
  },
];
export const ticketTypes = ["GA", "VIP"];

export function decodeSchema(schema: string): DecodedSchemaItem[] | null {
  try {
    const frag = ethers.utils.FunctionFragment.from(`testFunc(${schema})`);

    return frag.inputs.map((paramType) => {
      const { name, type } = paramType;

      return { name, type, value: type === "bool" ? false : "" };
    });
  } catch (e) {
    console.log("Decoding schema fail", e);
    return null;
  }
}

export function getWalletProvider() {
  return ethers.providers.getDefaultProvider();
}

export function getQueryProvider() {
  return new ethers.providers.JsonRpcProvider("https://cloudflare-eth.com/");
}

export function decodeSchemaWithAttestationData(
  schema: string,
  attestationData: EIP712AttestationParams
): DecodedSchemaItem[] | null {
  try {
    const decodedSchema = decodeSchema(schema);

    if (decodedSchema) {
      const types = decodedSchema.map((item) => item.type);

      const decodedData = ethers.utils.defaultAbiCoder.decode(
        types,
        attestationData.data
      );

      return decodedSchema.map((schemaItem, i) => ({
        ...schemaItem,
        value: decodedData[i],
      }));
    }
  } catch (e) {
    console.log("Decoding schema with attestation fail", e);
    return null;
  }

  return null;
}

export function encodeSchemaData(inputValues: DecodedSchemaItem[]): string {
  const types = inputValues.map((iv) => iv.type);
  const values = inputValues.map((iv) =>
    iv.type === "bytes"
      ? ethers.utils.toUtf8Bytes(iv.value as string)
      : iv.type === "bytes32"
      ? ethers.utils.formatBytes32String(iv.value as string)
      : iv.value
  );

  return ethers.utils.defaultAbiCoder.encode(types, values);
}

export function getSchemaFromGraphByIndex(schemaIndex: string) {
  return axios.post(GRAPH_API_BASE_URL, {
    query: `{
  asschemas(where:{index: "${schemaIndex}"} ) {
    id
    schemaData
    schema
    creator
    index
  }
}`,
  });
}

export function getSchemaFromGraphBySchemaUUID(uuid: string) {
  return axios.post(GRAPH_API_BASE_URL, {
    query: `{
  asschemas(where:{id: "${uuid}"} ) {
    id
    schemaData
    schema
    creator
    index
  }
}`,
  });
}
