import { EIP712AttestationParams } from "@ethereum-attestation-service/sdk";
import { useQuery } from "react-query";
import {
  decodeSchemaWithAttestationData,
  getSchemaFromGraphBySchemaUUID,
} from "../utils";
import { GraphSchema } from "../types";

export function useSchemaByUuid(attestationData?: EIP712AttestationParams) {
  return useQuery(
    ["AS", attestationData?.schema],
    async () => {
      if (!attestationData) {
        return;
      }

      const data = await getSchemaFromGraphBySchemaUUID(
        attestationData?.schema as string
      );

      const schema: GraphSchema | undefined = data?.data?.data?.asschemas[0];

      if (schema) {
        try {
          return decodeSchemaWithAttestationData(
            schema.schema,
            attestationData
          );
        } catch (e) {
          console.log("decoding errors", e);
        }
      }
    },
    {
      enabled: !!attestationData?.schema,
      staleTime: 60 * 60 * 24 * 1000,
    }
  );
}
