import { Platform, ScrollView, Text, View } from "react-native";
import styled from "styled-components/native";
import { useStore } from "../hooks/useStore";
import { DiscoverItem } from "../components/DiscoverItem";
import { AccountHeader } from "../components/AccountHeader";
import { attestationTypes } from "../utils";
import { NavigationType } from "../types";
import { useEffect } from "react";
import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import { HomeDataPill } from "../components/HomeDataPill";

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: #fff;
  padding-bottom: -40px;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;
const RecentHistoryTitle = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: #768592;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.32px;
  margin-top: 20px;
`;

type Props = {
  navigation: NavigationType;
};

export function HomeScreen({ navigation }: Props) {
  const walletInfo = useStore((store) => store.walletInfo);

  useEffect(() => {
    async function checkForLink() {
      const url = await Linking.getInitialURL();

      if (url) {
        let { hostname, path, queryParams } = Linking.parse(url);
        if (queryParams.base64AttestationData) {
          navigation.navigate("DisplayDecodedAttestationScreen", {
            encodedQRString: queryParams.base64AttestationData,
          });
        }
      }
    }

    if (Platform.OS !== "web") {
      checkForLink();

      Linking.addEventListener("url", (eventType) => {
        let { hostname, path, queryParams } = Linking.parse(eventType.url);
        navigation.navigate("DisplayDecodedAttestationScreen", {
          encodedQRString: queryParams.base64AttestationData,
        });
      });
    }

    return () => {
      Linking.removeEventListener("url", () => {});
    };
  }, []);

  if (!walletInfo) {
    return <View />;
  }
  return (
    <Container>
      {/*<TopSpacer />*/}
      <Padding>
        <AccountHeader address={walletInfo.address} hideAddress={false} />

        <HomeDataPill />
        <RecentHistoryTitle>Attest</RecentHistoryTitle>
      </Padding>

      <ScrollView>
        {attestationTypes.map((type) => (
          <DiscoverItem attestationType={type} key={type.schemaAddress} />
        ))}
      </ScrollView>
    </Container>
  );
}
