import React from "react";
import { Text, View } from "react-native";

import styled from "styled-components/native";
import { SexySwitch } from "./imported/rn-pretty/sexy-switch";
import { BigInput } from "./imported/rn-pretty/big-input";
import { DecodedSchemaItem } from "../types";
import { AddressInputWithScan } from "./AddressInputWithScan";
import { camelCaseToTitleCase } from "../utils";

const Name = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.darkest};
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  text-transform: capitalize;
`;

const Container = styled(View)`
  margin-bottom: 8px;
`;

type Props = {
  input: DecodedSchemaItem;
  onChange: (val: boolean | string) => void;
};

export function AutoInput({ input, onChange }: Props) {
  const { value, type, name } = input;

  const formattedName = camelCaseToTitleCase(name);

  switch (type) {
    case "bool":
      return (
        <Container>
          <Name>{formattedName} </Name>
          <SexySwitch
            style={{ marginTop: 2 }}
            titles={["False", "True"]}
            onSelect={(index) => onChange(!!index)}
            selected={value ? 1 : 0}
          />
        </Container>
      );
    case "address":
      return (
        <Container>
          <AddressInputWithScan
            title={formattedName}
            placeholder={formattedName}
            value={value as any}
            onChangeText={(val) => onChange(val)}
          />
        </Container>
      );
    case "uint8":
    case "uint16":
    case "uint24":
    case "uint32":
    case "uint40":
    case "uint48":
    case "uint56":
    case "uint64":
    case "uint72":
    case "uint80":
    case "uint88":
    case "uint96":
    case "uint104":
    case "uint112":
    case "uint120":
    case "uint128":
    case "uint136":
    case "uint144":
    case "uint152":
    case "uint160":
    case "uint168":
    case "uint176":
    case "uint184":
    case "uint192":
    case "uint200":
    case "uint208":
    case "uint216":
    case "uint224":
    case "uint232":
    case "uint240":
    case "uint248":
    case "uint256":
    case "bytes":
    case "bytes32":
    case "string":
      return (
        <Container>
          <BigInput
            title={formattedName}
            placeholder={formattedName}
            value={value as any}
            onChangeText={(val) => onChange(val)}
          />
        </Container>
      );
      break;
    default:
      return null;
  }
}
