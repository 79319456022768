import { View, Text, TouchableOpacity } from "react-native";
import styled, { useTheme } from "styled-components/native";
import FAIcon from "@expo/vector-icons/FontAwesome";
import { useNavigation } from "@react-navigation/native";
import { AttestationType, NavigationType } from "../types";

const Container = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.gray};
`;

const Left = styled(View)``;

const Right = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.darkest};
  font-size: 18px;
  font-family: ${({ theme }) => theme.primaryFontFamily};
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.gray};
`;

type Props = {
  attestationType: AttestationType;
  beforeOpen?: () => void;
};

export function DiscoverItem({ attestationType, beforeOpen }: Props) {
  const navigation = useNavigation<NavigationType>();

  const theme = useTheme();
  return (
    <Container
      onPress={() => {
        if (beforeOpen) {
          beforeOpen();
        }

        navigation.navigate("CustomAttestationScreen", {
          schemaIndex: attestationType.asIndex,
        });
      }}
    >
      <Left>
        <Title>{attestationType.name}</Title>
        <Subtitle>{attestationType.subtitle}</Subtitle>
      </Left>
      <Right>
        <FAIcon
          name={attestationType.faIconName as any}
          size={30}
          color={theme.darkest}
        />
      </Right>
    </Container>
  );
}
