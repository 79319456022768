import styled from "styled-components/native";
import {
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native";
import { useState } from "react";
import * as Clipboard from "expo-clipboard";

const Container = styled(TouchableOpacity)``;

const Address = styled(Text)`
  font-family: ${({ theme }) => theme.monospaceBoldfontFamily};
  color: ${({ theme }) => theme.darkest};
  font-size: 20px;
  text-align: center;
`;

export function AddressDisplay(props: {
  address: string;
  style?: StyleProp<TextStyle>;
}) {
  const [showCopyText, setShowCopyText] = useState(false);

  return (
    <Container
      onPress={async () => {
        console.log("aaa", props.address);
        Clipboard.setString(props.address);
        setShowCopyText(true);
        setTimeout(() => setShowCopyText(false), 600);

        setTimeout(async () => {
          const text = await Clipboard.getStringAsync();
          console.log("cop", text);
        }, 2000);
      }}
    >
      <Address
        style={props.style}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
      >
        {showCopyText ? "Copied!" : props.address ?? "Loading address..."}
      </Address>
    </Container>
  );
}
