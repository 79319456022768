import { useStore } from "./src/hooks/useStore";
import { NavigationContainer } from "@react-navigation/native";
import { FreshStartScreen } from "./src/containers/FreshStartScreen";
import { CreateIdentityScreen } from "./src/containers/CreateIdentityScreen";

import { CustomAttestationScreen } from "./src/containers/AttestationTypeScreens/CustomAttestationScreen";

import { HomeScreen } from "./src/containers/HomeScreen";
import { ScanScreen } from "./src/containers/ScanScreen";
import { SettingsScreen } from "./src/containers/SettingsScreen";
import { DiscoverScreen } from "./src/containers/DiscoverScreen";
import { ViewContactScreen } from "./src/containers/ViewContactScreen";
import { QRDisplayScreen } from "./src/containers/QRDisplayScreen";
import { AccountDetailsScreen } from "./src/containers/AccountDetailsScreen";
import { CollectionScreen } from "./src/containers/CollectionScreen";
import { DisplayDecodedAttestationScreen } from "./src/containers/DisplayDecodedAttestationScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useTheme } from "styled-components/native";
import { View } from "react-native";
import { ContactsScreen } from "./src/containers/ContactsScreen";
import { AddContactScreen } from "./src/containers/AddContactScreen";

const Stack = createNativeStackNavigator();
const ModalStack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

function Empty() {
  return <View />;
}

function NonModals() {
  const seedPhrase = useStore((store) => store.seedPhrase);
  const theme = useTheme();

  return (
    <>
      {!seedPhrase ? (
        <ModalStack.Navigator
          screenOptions={{
            headerShown: false,
            animation: "fade_from_bottom",
          }}
        >
          <>
            <ModalStack.Screen
              name="FreshStartScreen"
              component={FreshStartScreen}
            />
            <ModalStack.Screen
              name="CreateIdentityScreen"
              component={CreateIdentityScreen}
            />
          </>
        </ModalStack.Navigator>
      ) : (
        <Tab.Navigator
          screenOptions={{
            tabBarActiveTintColor: theme.primary,
            headerShown: false,
          }}
        >
          <Tab.Screen
            name="HomeScreen"
            component={HomeScreen}
            options={{
              tabBarLabel: "Identity",
              tabBarIcon: ({ color, size, focused }) => (
                <Ionicons
                  name={focused ? "person" : "person-outline"}
                  size={size}
                  color={color}
                />
              ),
            }}
          />
          <Tab.Screen
            name="DiscoverScreen"
            component={DiscoverScreen}
            options={{
              tabBarLabel: "Discover",
              tabBarIcon: ({ color, size, focused }) => (
                <Ionicons
                  name={focused ? "md-compass" : "md-compass-outline"}
                  size={size}
                  color={color}
                />
              ),
            }}
          />
          <Tab.Screen
            name="FakeScanScreen"
            component={Empty}
            options={{
              tabBarLabel: "Scan",
              tabBarIcon: ({ color, size, focused }) => (
                <Ionicons
                  name={focused ? "md-scan" : "md-scan-outline"}
                  size={size}
                  color={color}
                />
              ),
            }}
            listeners={({ navigation }) => ({
              tabPress: (e) => {
                e.preventDefault();
                navigation.navigate("ScanScreen");
              },
            })}
          />
          <Tab.Screen
            name="CollectionScreen"
            component={CollectionScreen}
            options={{
              tabBarLabel: "Collection",
              tabBarIcon: ({ color, size, focused }) => (
                <Ionicons
                  name={focused ? "md-apps" : "md-apps-outline"}
                  size={size}
                  color={color}
                />
              ),
            }}
          />
          <Tab.Screen
            name="SettingsScreen"
            component={SettingsScreen}
            options={{
              tabBarLabel: "Settings",
              tabBarIcon: ({ color, size, focused }) => (
                <Ionicons
                  name={focused ? "md-settings" : "md-settings-outline"}
                  size={size}
                  color={color}
                />
              ),
            }}
          />
        </Tab.Navigator>
      )}
    </>
  );
}

export function Router() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{ headerShown: false, presentation: "modal" }}
      >
        <Stack.Screen name="MainContainer" component={NonModals} />
        <Stack.Screen name="ScanScreen" component={ScanScreen} />
        <Stack.Screen name="QRDisplayScreen" component={QRDisplayScreen} />
        <Stack.Screen
          name="AccountDetailsScreen"
          component={AccountDetailsScreen}
        />
        <Stack.Screen
          name="CustomAttestationScreen"
          component={CustomAttestationScreen}
        />
        <Stack.Screen name="ViewContactScreen" component={ViewContactScreen} />
        <Stack.Screen
          name="ContactsScreen"
          component={ContactsScreen}
          options={{ presentation: "card" }}
        />
        <Stack.Screen name="AddContactScreen" component={AddContactScreen} />
        <Stack.Screen
          name="DisplayDecodedAttestationScreen"
          component={DisplayDecodedAttestationScreen}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
