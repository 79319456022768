import { View } from "react-native";
import styled from "styled-components/native";
import { useStore } from "../hooks/useStore";
import TopSpacer from "../components/TopSpacer";
import {
  SettingsData,
  SettingsScreen as RNSettingsScreen,
} from "react-native-settings-screen";
import { useEffect } from "react";
import { getWalletProvider } from "../utils";

const Container = styled(View)`
  flex: 1;
  background-color: #fff;
`;

export function SettingsScreen() {
  const reset = useStore((store) => store.reset);

  const data: SettingsData = [
    {
      type: "CUSTOM_VIEW",
      key: "hero",
      render: () => (
        <>
          <View style={{ height: 40 }} />
        </>
      ),
    },
    {
      type: "SECTION",
      header: "DATA".toUpperCase(),
      // footer: 'v' + appVersion,
      rows: [
        {
          title: "Export data",

          onPress: async () => {
            // reset();
          },
        },
      ],
    },
    {
      type: "SECTION",
      header: "Account".toUpperCase(),
      // footer: 'v' + appVersion,
      rows: [
        {
          title: "Reset App",
          titleStyle: {
            color: "red",
          },
          onPress: async () => {
            reset();
          },
        },
      ],
    },
  ];

  return (
    <Container>
      <RNSettingsScreen data={data} />
    </Container>
  );
}
