import { Dimensions, View } from "react-native";
import styled from "styled-components/native";
import TopSpacer from "../components/TopSpacer";
import QRCode from "react-native-qrcode-svg";
import { TopBar } from "../components/TopBar";
import { NavigationType } from "../types";
import { formatAddress } from "../utils";

const Container = styled(View)`
  flex: 1;
  background-color: #fff;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const QR = styled(View)`
  margin-top: 8px;
`;

type Props = {
  navigation: NavigationType;
  route: {
    params: {
      qrString: string;
      subtitle?: string;
    };
  };
};

export function QRDisplayScreen({ route, navigation }: Props) {
  const windowSize = Dimensions.get("window");

  return (
    <Container>
      <TopSpacer minimal={true} />
      <Padding>
        <TopBar
          name={"QR Display"}
          subtitle={route.params.subtitle}
          rightButtonText={"Close"}
          rightButtonOnPress={() => navigation.pop()}
        />
        <QR>
          <QRCode
            value={route.params.qrString}
            size={Math.min(windowSize.width - 24, 600)}
          />
        </QR>
      </Padding>
    </Container>
  );
}
