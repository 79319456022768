import { FlatList, View } from "react-native";
import { useQuery } from "react-query";
import axios from "axios";
import { GRAPH_API_BASE_URL } from "../../utils";
import { SchemaItem } from "../../components/SchemaItem";
import { GraphSchema } from "../../types";

type Props = {
  onSelect: (indexSelected: number) => void;
};

export function SchemaSearchList({ onSelect }: Props) {
  const { data } = useQuery(["Schemas"], () =>
    axios.post(GRAPH_API_BASE_URL, {
      query: `{
  asschemas(orderBy: index) {
    id
    schemaData
    schema
    creator
    index
  }
}`,
    })
  );

  console.log("da", data);

  return (
    <View>
      {data && (
        <FlatList<GraphSchema>
          contentInset={{ bottom: 180 }}
          data={data.data.data.asschemas}
          renderItem={({ item }) => (
            <SchemaItem item={item} onPress={() => onSelect(item.index)} />
          )}
        />
      )}
    </View>
  );
}
