import {
  StyleProp,
  Text,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from "react-native";
import React, { useState } from "react";
import styled from "styled-components/native";

type TitleProps = {
  focused: boolean;
};

const Title = styled(Text)<TitleProps>`
  color: ${(props) => (props.focused ? props.theme.darkest : props.theme.gray)};
  font-size: 12px;
`;

type InputProps = {
  focused: boolean;
  text: string | undefined;
};

const InputContainer = styled(View)`
  position: relative;
`;

const Input = styled(TextInput)<InputProps>`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: #2e414b;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom-width: 1px;
  border-color: ${(props) =>
    props.focused ? props.theme.primary : props.text ? "#B3BFC9" : "#E7EEF0"};
  margin-bottom: 20px;
`;

interface Props extends TextInputProps {
  title: string;
  style?: StyleProp<ViewStyle>;
  rightElement?: JSX.Element;
  forceTitleOff?: boolean;
}

export function BigInput(props: Props) {
  const { title, onChangeText, value, style } = props;
  const [focused, setFocused] = useState(false);
  const [text, setText] = useState(value);

  return (
    <View style={style}>
      {text && !props.forceTitleOff ? (
        <Title focused={focused}>{title}</Title>
      ) : null}
      <InputContainer>
        <Input
          focused={focused}
          text={text}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          {...props}
          onChangeText={(eventText) => {
            setText(eventText);
            if (onChangeText) {
              onChangeText(eventText);
            }
          }}
          placeholder={title}
          placeholderTextColor={"#999"}
        />
        {props.rightElement ? props.rightElement : null}
      </InputContainer>
    </View>
  );
}
