import produce from "immer";
import create from "zustand";
import { devtools } from "zustand/middleware";

type InitialState = {
  /** The to address on any form. Used globally so we can use qr scanner modal */
  toAddress: string;
};

const initialState: InitialState = {
  toAddress: "",
};

const reducer = (set: any) => ({
  ...initialState,
  setToAddress: (address: string) =>
    set({ toAddress: address }, undefined, "setToAddress"),
});

export const useTmpStore = create(__DEV__ ? devtools(reducer) : reducer);
