import { StatusBar, Text, View } from "react-native";
import styled from "styled-components/native";
import TopSpacer from "../components/TopSpacer";
import { BigButton } from "../components/imported/rn-pretty/big-button";
import { NavigationType } from "../types";

const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.primary};
  padding: 0 12px;
`;

const Button = styled(BigButton)`
  margin-top: 12px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 34px;
  color: #fff;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 40px;
`;

type Props = {
  navigation: NavigationType;
};

export function FreshStartScreen({ navigation }: Props) {
  return (
    <Container>
      <StatusBar barStyle={"light-content"} />
      <TopSpacer />
      <Title>Attest</Title>

      <Button
        onPress={() => navigation.navigate("CreateIdentityScreen")}
        text={"Create new identity"}
        inverted={true}
      />
      <Button
        onPress={() => {}}
        text={"Import existing identity"}
        inverted={false}
      />
    </Container>
  );
}
