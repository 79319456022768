import { ThemeProvider } from "styled-components/native";
import { useStore } from "./src/hooks/useStore";
import {
  Rubik_400Regular,
  Rubik_500Medium,
  useFonts,
} from "@expo-google-fonts/rubik";

import {
  RobotoMono_400Regular,
  RobotoMono_500Medium,
} from "@expo-google-fonts/roboto-mono";

import { Router } from "./Router";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme } from "./theme";

export default function App() {
  const hasHydrated = useStore((store) => store._hasHydrated);
  const store = useStore();
  const queryClient = new QueryClient();

  console.log(store);

  let [fontsLoaded] = useFonts({
    Rubik_400Regular,
    Rubik_500Medium,
    RobotoMono_400Regular,
    RobotoMono_500Medium,
  });

  if (!hasHydrated || !fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
