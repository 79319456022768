import { Text, TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { GraphSchema } from "../types";

const Container = styled(TouchableOpacity)`
  padding: 8px;
`;
const Index = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.dark};
  font-size: 16px;
`;

const Subtitle = styled(Text)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 14px;
  color: ${({ theme }) => theme.gray};
`;

type Props = {
  item: GraphSchema;
  onPress: () => void;
};

export function SchemaItem({ item, onPress }: Props) {
  const { schema, index } = item;

  return (
    <Container onPress={onPress}>
      <Index>{index}</Index>
      <Subtitle>{schema}</Subtitle>
    </Container>
  );
}
