import { AttestationShareablePackageObject } from "../types";
import gzip from "gzip-js";

export function zipAndEncodeToBase64(
  qrPackage: AttestationShareablePackageObject
) {
  const jsoned = JSON.stringify(qrPackage);

  const gzipped = gzip.zip(jsoned, { level: 9 });
  return btoa(String.fromCharCode.apply(null, gzipped));
}

export function decodeBase64ZippedQR(
  base64: string
): AttestationShareablePackageObject {
  const fromBase64 = atob(base64)
    .split("")
    .map(function (c) {
      return c.charCodeAt(0);
    });

  const jsonStr = gzip
    .unzip(fromBase64)
    .map((ch) => String.fromCharCode(ch))
    .join("");

  return JSON.parse(jsonStr);
}
