import {
  ActivityIndicator,
  TextInputProps,
  TouchableOpacity,
  View,
} from "react-native";
import { BigInput } from "./imported/rn-pretty/big-input";
import styled from "styled-components/native";
import FAIcon from "@expo/vector-icons/FontAwesome";
import { useNavigation } from "@react-navigation/native";
import { NavigationType } from "../types";
import { getQueryProvider } from "../utils";
import { useState } from "react";

const Container = styled(View)`
  position: relative;
`;

const ScanContainer = styled(TouchableOpacity)`
  position: absolute;
  right: 0;
  top: 2px;
  background-color: #fff;
  padding: 8px;
`;

const Icon = styled(FAIcon)``;

interface Props extends TextInputProps {
  title: string;
  value: string;
  onChangeText: (text: string) => void;
  forceTitleOff?: boolean;
}

const provider = getQueryProvider();

export function AddressInputWithScan(props: Props) {
  const navigation = useNavigation<NavigationType>();
  const [isFetchingENS, setIsFetchingENS] = useState(false);

  return (
    <Container>
      <BigInput
        {...props}
        onBlur={async () => {
          console.log("blur");

          if (props.value && props.value.includes(".eth")) {
            console.log("setting");
            setIsFetchingENS(true);

            const address = await provider.resolveName(props.value);
            if (address && props.onChangeText) {
              props.onChangeText(address);
            }
            setIsFetchingENS(false);
          }
        }}
        forceTitleOff={props.forceTitleOff}
        rightElement={
          <ScanContainer
            onPress={() =>
              navigation.navigate("ScanScreen", { isAddressScan: true })
            }
          >
            {isFetchingENS ? (
              <ActivityIndicator size={"small"} color={"#666"} />
            ) : (
              <Icon name={"qrcode"} size={22} />
            )}
          </ScanContainer>
        }
      />
    </Container>
  );
}
