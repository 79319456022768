import { ScrollView, Text, View } from "react-native";
import styled from "styled-components/native";
import { DiscoverItem } from "../components/DiscoverItem";
import { attestationTypes } from "../utils";
import { SafeAreaView } from "react-native-safe-area-context";

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: #fff;
  padding-bottom: -40px;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.darkest};
  text-align: left;
  font-size: 22px;
  line-height: 28px;
`;

const RecentHistoryTitle = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: #768592;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.32px;
  margin-top: 20px;
`;

export function DiscoverScreen() {
  return (
    <Container>
      <Padding>
        <Title>Discover</Title>
        <RecentHistoryTitle>POSSIBLE ACTIONS</RecentHistoryTitle>
      </Padding>

      <ScrollView>
        {attestationTypes.map((type) => (
          <DiscoverItem attestationType={type} key={type.schemaAddress} />
        ))}
      </ScrollView>
    </Container>
  );
}
