import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { GoBack } from "../components/GoBack";
import { NavigationType } from "../types";
import { useStore } from "../hooks/useStore";
import { attestationTypes } from "../utils";
import { DiscoverItem } from "../components/DiscoverItem";
import { useTmpStore } from "../hooks/useTmpStore";

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: #fff;
  padding-bottom: -40px;
  position: relative;
`;

const Address = styled.Text`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.gray};
  font-size: 16px;
  margin-top: 8px;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

type props = {
  navigation: NavigationType;
  route: {
    params: {
      address: string;
    };
  };
};

export function ViewContactScreen({ navigation, route }: props) {
  const setToAddress = useTmpStore((store) => store.setToAddress);
  const contacts = useStore((store) => store.contacts);
  const contact = contacts.find(
    (contact) =>
      contact.address.toLowerCase() === route.params.address.toLowerCase()
  );

  return (
    <Container>
      <Padding>
        <GoBack
          title={contact?.name ?? "Unknown contact"}
          onBack={() => navigation.pop()}
          isModal={true}
        />
        <Address numberOfLines={1} adjustsFontSizeToFit={true}>
          {contact?.address}
        </Address>

        <ScrollView>
          {attestationTypes.map((type) => (
            <DiscoverItem
              attestationType={type}
              key={type.schemaAddress}
              beforeOpen={() => {
                navigation.pop();
                setToAddress(contact?.address as string);
              }}
            />
          ))}
        </ScrollView>
      </Padding>
    </Container>
  );
}
