import { DefaultTheme } from "styled-components/native";

export const theme: DefaultTheme = {
  primary: "#4e92c4",
  dark: "#1d3d52",
  darkest: "#1d3d52",
  lightGray: "#c0c7cc",
  gray: "#8E99A4",
  green: "#298a29",
  red: "#ff0000",
  primaryFontFamily: "Rubik_400Regular",
  primaryBoldFontFamily: "Rubik_500Medium",
  monospacefontFamily: "RobotoMono_400Regular",
  monospaceBoldfontFamily: "RobotoMono_500Medium",
  containerPadding: 12,
};
