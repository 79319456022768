import { Text, TouchableOpacity, View } from "react-native";
import { Contact, NavigationType } from "../types";
import styled from "styled-components/native";
import { formatAddress } from "../utils";
import AppleStyleSwipeableRow from "./AppleStyleSwipeableRow";
import { useStore } from "../hooks/useStore";
import { useNavigation } from "@react-navigation/native";

const Inner = styled(TouchableOpacity)`
  padding: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.gray};
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.darkest};
  font-size: 18px;
  font-family: ${({ theme }) => theme.primaryFontFamily};
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.gray};
  margin-top: 4px;
`;

type Props = {
  data: Contact;
};

export function ContactItem({ data: contact }: Props) {
  const removeContact = useStore((store) => store.removeContact);
  const navigation = useNavigation<NavigationType>();

  return (
    <AppleStyleSwipeableRow
      onDelete={() => {
        removeContact(contact.address);
      }}
    >
      <Inner
        onPress={() => {
          navigation.navigate("ViewContactScreen", {
            address: contact.address,
          });
        }}
      >
        <Title>{contact.name}</Title>
        <Subtitle>{contact.address}</Subtitle>
      </Inner>
    </AppleStyleSwipeableRow>
  );
}
