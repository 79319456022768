import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { GoBack } from "../components/GoBack";
import { NavigationType } from "../types";
import { ContactItem } from "../components/ContactItem";
import dayjs from "dayjs";
import FAIcon from "@expo/vector-icons/FontAwesome";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useStore } from "../hooks/useStore";

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: #fff;
  padding-bottom: -40px;
  position: relative;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

const Circle = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  border-radius: 80px;
  background-color: ${({ theme }) => theme.primary};
  box-shadow: 0 0 3px #626262;
`;

type props = {
  navigation: NavigationType;
};

export function ContactsScreen({ navigation }: props) {
  const contacts = useStore((store) => store.contacts);

  return (
    <Container>
      <Padding>
        <GoBack title={"Contacts"} onBack={() => navigation.pop()} />
      </Padding>

      <ScrollView>
        {contacts.map((contact, i) => (
          <ContactItem data={contact} key={i} />
        ))}
      </ScrollView>
      <Circle onPress={() => navigation.navigate("AddContactScreen")}>
        <Ionicons name={"person-add-outline"} size={30} color={"#FFF"} />
      </Circle>
    </Container>
  );
}
