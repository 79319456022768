import { Text } from "react-native";
import { useQuery } from "react-query";
import { formatAddress, getQueryProvider } from "../utils";
import { ethers } from "ethers";

type Props = {
  address: string;
  longForm?: boolean;
  showBoth?: boolean;
};

const provider = getQueryProvider();

export function AddressOrENSText({
  address,
  longForm,
  showBoth,
  ...props
}: Props) {
  const { data: ensName } = useQuery(
    ["getEnsName", address],
    () => provider.lookupAddress(address),
    {
      enabled:
        address.length > 1 &&
        ethers.utils.isAddress(address) &&
        address.toLowerCase() !== ethers.constants.AddressZero.toLowerCase(),
      cacheTime: 60 * 60,
    }
  );
  return (
    <Text {...props}>
      {ensName}{" "}
      {(!ensName || showBoth) && (longForm ? address : formatAddress(address))}
    </Text>
  );
}
