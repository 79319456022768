import { useEffect, useState } from "react";
import * as jdenticon from "jdenticon";
import { SvgXml } from "react-native-svg";
import { Platform, View } from "react-native";
import styled from "styled-components/native";

type ContainerProps = {
  size: number;
};

const Container = styled(View)<ContainerProps>`
  background-color: #fff;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

type Props = {
  address: string;
  size: number;
};

export function Identicon({ address, size }: Props) {
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    const icon = jdenticon.toSvg(address, size);
    setIcon(icon);
  }, []);

  if (!address || !icon) return null;

  return (
    <Container size={size}>
      {Platform.OS === "web" ? (
        <img
          style={{ borderRadius: 8 }}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
        />
      ) : (
        <SvgXml xml={icon} height={size} width={size} />
      )}
    </Container>
  );
}
