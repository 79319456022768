import { View } from "react-native";
import styled, { useTheme } from "styled-components/native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { NavigationType } from "../types";
import { useStore } from "../hooks/useStore";

const Outer = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
`;

const Container = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.primary};
  border-width: 1px;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.primary}
  font-family: ${({ theme }) => theme.primaryFontFamily};
`;
const Bold = styled.Text`
  color: ${({ theme }) => theme.primary}
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
`;

const Icon = styled(Ionicons)`
  margin-right: 4px;
`;

export function HomeDataPill() {
  const contacts = useStore((store) => store.contacts);
  const navigation = useNavigation<NavigationType>();
  const theme = useTheme();

  return (
    <Outer>
      <Container onPress={() => navigation.navigate("ContactsScreen")}>
        <Icon name={"people-outline"} color={theme.primary} size={18} />
        <Text>
          Contacts <Bold>{contacts.length}</Bold>
        </Text>
      </Container>
    </Outer>
  );
}
