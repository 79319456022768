import { Text, TouchableOpacity, View } from "react-native";
import { CollectionItem, NavigationType } from "../types";
import styled, { useTheme } from "styled-components/native";
import { formatAddress, getAttestationTypeBySchemaAddress } from "../utils";
import dayjs from "dayjs";
import { useNavigation } from "@react-navigation/native";
import FAIcon from "@expo/vector-icons/FontAwesome";
import { useMemo } from "react";
import { AddressOrENSText } from "./AddressOrENSText";
import { decodeBase64ZippedQR } from "../utils/encodingUtils";

const Outer = styled(View)`
  flex-direction: row;
  padding: 6px;
`;
const Left = styled(View)``;

const Right = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
`;
const Container = styled(TouchableOpacity)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.darkest};
  font-size: 16px;
  margin-bottom: 2px;
`;
const Gray = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.lightGray};
`;
const Date = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.gray};
`;

type Props = {
  item: CollectionItem;
  sectionName: string;
};

export function CollectionItemBlock({ item, sectionName }: Props) {
  const {
    readableType,
    timestamp,
    uuid,
    tmpUUID,
    encodedAttestationPackageObject,
    recipientAddress,
  } = item;

  const navigation = useNavigation<NavigationType>();
  const theme = useTheme();

  const decodedAttestationPackage = useMemo(
    () => decodeBase64ZippedQR(encodedAttestationPackageObject),
    [encodedAttestationPackageObject]
  );

  const { schema } = decodedAttestationPackage.sig.data.message;
  const attestationType = getAttestationTypeBySchemaAddress(schema);

  return (
    <Outer>
      <Container
        onPress={() => {
          navigation.navigate("DisplayDecodedAttestationScreen", {
            encodedQRString: encodedAttestationPackageObject,
            storageUuid: uuid ?? tmpUUID,
          });
        }}
      >
        <Left>
          <Title>{attestationType?.shortName ?? readableType}</Title>
          {sectionName === "sent" && (
            <Gray>
              To: <AddressOrENSText address={recipientAddress} />
            </Gray>
          )}
          {sectionName === "received" && (
            <Gray>
              {`From: ${formatAddress(decodedAttestationPackage.signer, 12)}`}{" "}
            </Gray>
          )}

          <Date>{dayjs.unix(timestamp).format("M/D/YYYY h:mma ")}</Date>
        </Left>
        <Right>
          {attestationType && attestationType.faIconName && (
            <FAIcon
              name={attestationType.faIconName as any}
              size={30}
              color={theme.darkest}
            />
          )}
        </Right>
      </Container>
    </Outer>
  );
}
