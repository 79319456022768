import React from "react";
import { View, Platform, StatusBar } from "react-native";

interface props {
  height?: number;
  minimal?: boolean;
}

const TopSpacer = React.memo((props: props) => {
  const styles = {
    container: {
      // if the user is using an iPhone X, we need
      // to add an extra bit of padding for the notch
      height: props.height
        ? props.height
        : Platform.OS === "android"
        ? StatusBar.currentHeight
        : (StatusBar.currentHeight ? StatusBar.currentHeight : 0) + 44,
    },
  };

  if (Platform.OS === "web") {
    return <View style={{ height: 10 }} />;
  }

  if (props.minimal && Platform.OS === "ios") {
    return <View style={{ height: 12 }} />;
  }

  return <View style={[styles.container]} />;
});

export default TopSpacer;
