import {
  Platform,
  SafeAreaView as SafeAreaViewIos,
  ScrollView,
  Text,
  View,
} from "react-native";
import styled from "styled-components/native";

import { useEffect, useState } from "react";
import TopSpacer from "../components/TopSpacer";
import { NavigationType } from "../types";
import { useTmpStore } from "../hooks/useTmpStore";
import { SafeAreaView as SafeAreaViewAndroid } from "react-native-safe-area-context";
import { AddressInputWithScan } from "../components/AddressInputWithScan";
import { BigInput } from "../components/imported/rn-pretty/big-input";
import { BigButton } from "../components/imported/rn-pretty/big-button";
import { useStore } from "../hooks/useStore";
import dayjs from "dayjs";

const Container = styled(View)`
  flex: 1;
  background-color: #fff;
`;

const Padding = styled(View)`
  padding: 0 ${({ theme }) => theme.containerPadding}px;
`;

type Props = {
  navigation: NavigationType;
};

export function AddContactScreen({ navigation }: Props) {
  const [name, setName] = useState("");
  const toAddress = useTmpStore((store) => store.toAddress);
  const setToAddress = useTmpStore((store) => store.setToAddress);
  const addContact = useStore((store) => store.addContact);

  // Clear global address on dismount
  useEffect(() => {
    setToAddress("");

    return () => setToAddress("");
  }, []);

  const SafeComponent =
    Platform.OS === "ios" ? SafeAreaViewIos : SafeAreaViewAndroid;

  return (
    <SafeComponent style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Container>
        <TopSpacer minimal={true} />
        <Padding>
          <AddressInputWithScan
            title={"Contact address (or ENS)"}
            onChangeText={setToAddress}
            value={toAddress}
          />
          <BigInput
            title={"Contact name"}
            onChangeText={(text) => setName(text)}
          />

          <BigButton
            onPress={() => {
              addContact({
                address: toAddress.toLowerCase(),
                addedTime: dayjs().unix(),
                name,
              });

              navigation.pop();
            }}
            text={"Add contact"}
          />
        </Padding>
      </Container>
    </SafeComponent>
  );
}
