import { NativeEventSubscription, Text, View } from "react-native";
import styled from "styled-components/native";
import Ionicons from "@expo/vector-icons/Ionicons";

const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.primaryBoldFontFamily};
  color: ${({ theme }) => theme.darkest};
  text-align: left;
  font-size: 22px;
  line-height: 28px;
`;

const Icon = styled(Ionicons)`
  margin-right: 4px;
`;

type Props = {
  title: string;
  onBack: () => void;
  isModal?: boolean;
};

export function GoBack({ title, onBack, isModal }: Props) {
  return (
    <Container>
      <Icon
        name={isModal ? "close-outline" : "chevron-back-outline"}
        size={26}
        onPress={onBack}
      />
      <Title>{title}</Title>
    </Container>
  );
}
